import React from "react";
import SimplyLogo from "../../Images/SimplyLogo";

const Footer = () => {
  return (
    <div className="pt-4 pb-12 bg-black px-[40px] flex flex-col gap-2">
      <div className="md:mx-auto md:mb-8">
        <SimplyLogo />
      </div>
      <div className="text-white">
        <div className="flex flex-row md:flex-col md:gap-4 justify-between items-center">
          <p className="text-white opacity-[60%] text-[16px]">
            © 2024 Simply. All rights reserved.
          </p>
          <ul className="flex flex-row  gap-10 md:gap-5">
            <li
              onClick={() => {
                window.open("/privacy", "_blank");
              }}
              className="cursor-pointer hover:text-gray-200 text-[16px]"
            >
              Privacy Policy
            </li>
            <li className="cursor-pointer hover:text-gray-200 text-[16px]">
              Terms of Service
            </li>
            <li
              onClick={() => {
                window.open("/contact-us", "_blank");
              }}
              className="cursor-pointer hover:text-gray-200 text-[16px]"
            >
              Help Center
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Footer;
