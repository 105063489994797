import React from "react";
import ProductImgOne from "../../Images/🍱 Bento (1).webp";

const EveryIndustry = () => {
  return (
    <div className="md:py-8 py-24 px-[40px]">
      <h1 className="font-[700] text-[36px] md:text-[32px] sm:text-[28px]  mb-6 text-center">
        Designed for Every Industry
      </h1>
      <p className="sm:px-[15px] text-center">
        Simply serves businesses across industries, helping you stay organized
        and efficient.
      </p>
      <div className="mt-10 flex justify-center items-center">
        {/* <ProductImgOne /> */}
        <img src={ProductImgOne} alt="" />
        
      </div>
    </div>
  );
};

export default EveryIndustry;
