import React, { useState } from "react";
import SimplyLogo from "../../Images/SimplyLogo";

const Navbar = () => {
  const [activeLink, setActiveLink] = useState("Home");
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleLinkClick = (link) => {
    setActiveLink(link);
    setIsMenuOpen(false); // Close menu after clicking a link

    if (link === "Home") {
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else if (link === "Privacy") {
      window.open("/privacy", "_blank");
    } else if (link === "Contact Us") {
      window.open("/contact-us", "_blank");
    }
  };

  return (
    <nav className="fixed top-0 left-0 w-full h-[80px] flex justify-between items-center px-[55px] md:px-[40px] sm:px-[10px] bg-white z-50">
      <div>
        <SimplyLogo />
      </div>

      {/* Mobile Navbar */}
      <ul className="flex md:hidden justify-center items-center text-base gap-6 cursor-pointer relative">
        {["Home", "Privacy", "Contact Us"].map((link) => (
          <li
            key={link}
            onClick={() => handleLinkClick(link)}
            className={`relative pb-2 ${
              activeLink === link ? "text-black font-[600]" : "text-gray-800"
            } hover:text-black transition duration-300`}
          >
            {link}
            <span
              className={`${
                activeLink === link ? "w-full" : "w-0"
              } block h-[2px] bg-black transition-all duration-300 ease-in-out absolute left-0 bottom-0`}
            ></span>
          </li>
        ))}
      </ul>

      {/* Hamburger Icon for Desktop */}
      <div
        className="hidden md:flex cursor-pointer"
        onClick={() => setIsMenuOpen(!isMenuOpen)}
      >
        {/* Hamburger icon */}
        <div className="space-y-1">
          <div className="w-6 h-0.5 bg-black"></div>
          <div className="w-6 h-0.5 bg-black"></div>
          <div className="w-6 h-0.5 bg-black"></div>
        </div>
      </div>

      {/* Desktop Menu */}
      {isMenuOpen && (
        <div className="absolute top-[80px] justify-center items-center left-0 w-full bg-white shadow-md z-40 hidden md:flex">
          <ul className="flex flex-col items-center text-base gap-6 py-4">
            {["Home", "Privacy", "Contact Us"].map((link) => (
              <li
                key={link}
                onClick={() => handleLinkClick(link)}
                className={`${
                  activeLink === link
                    ? "text-black font-[600]"
                    : "text-gray-800"
                } hover:text-black transition duration-300 cursor-pointer`}
              >
                {link}
              </li>
            ))}
          </ul>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
