import React from "react";
import ManageMentView from "../ManageMentView/ManageMentView";
import SimpleBigImage from "../SimpleBigImage/SimpleBigImage";

const Management = () => {
  return (
    <div className="md:py-8 py-24 px-[40px]" id="testimonials">
      <div className="flex flex-col text-center gap-5">
        <h1 className="text-2xl font-[700]">Efficient Inventory Management</h1>

        <p className="sm:px-[15px]">
          Say goodbye to manual stock-taking. The Simply App makes it easy to
          audit stock, and manage your warehouse efficiently—all from one
          platform.
          <br /> Track products and inventory in real-time, ensuring smooth
          business operations.
      </p>
      </div>
      <ManageMentView />
      <SimpleBigImage />
    </div>
  );
};

export default Management;
