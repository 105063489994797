import React from "react";

const Privacy = () => {
  return (
    <div className="px-2.5 bg-[linear-gradient(to_bottom,rgba(255,216,198,1),rgba(255,241,234,1))]">
      <div className="max-w-screen-xl mx-auto">
        <header className="flex flex-col lg:flex-row justify-between items-center py-5">
          <a href="/">
            <span className="font-bold text-slate-800">Simplystock</span>
            <span className="text-slate-500">.co</span>
          </a>
        </header>
      </div>

      <div className="max-w-screen-xl mx-auto px-5">
        <div className="mt-6 md:mt-8 text-center">
          <h1 className="text-4xl lg:text-5xl font-bold lg:tracking-tight">
            Privacy Policy
          </h1>
          <p className="text-lg mt-4 text-slate-600">
            By using our services, you agree to this policy.
          </p>
        </div>
      </div>
      <div className="flex flex-col gap-3 mx-auto max-w-4xl mt-12  text-slate-600">
        <p>
          Simplystock is a cloud-based inventory application available in
          Android and Web versions. We are committed to protecting the privacy
          and personal data of our users. This Privacy Policy explains how we
          collect, use, and protect the information obtained from users of the
          Simplystock application.
          <br />
        </p>
        <p>
          <strong>1. Data Collection</strong>
          <br />
          Simplystock collects user data for the purposes of application
          features.
          <br />
          The data we collect includes information provided directly by users
          during registration, such as name, email address, and transaction
          data.
          <br />
        </p>

        <p>
          <strong>2. Data Usage</strong>
          <br />
          The data collected is used for:
          <br />
          - Providing services and application features according to user needs.
          <br />
          - Enhancing the user experience and application performance.
          <br />
          - Sending notifications related to the use of the application.
          <br />
        </p>

        <p>
          <strong>3. Data Security</strong>
          <br />
          We take reasonable measures to protect user data from unauthorized
          access, alteration, disclosure, or destruction.
          <br />
          The data is stored on secure servers and protected with encryption
          technology.
          <br />
        </p>
        <p>
          <strong>4. Data Confidentiality</strong>
          <br />
          Simplystock
          <ins> will not disclose </ins>
          users' personal information to any third party, including banks and
          governments, unless required by applicable law or with the user's
          consent.
          <br />
        </p>
        <p>
          <strong>5. Data Deletion</strong>
          <br />
          You can submit a request to delete your data from the Simplystock
          application by contacting us via email.
        </p>
        <p>
          <strong>6. Changes to the Privacy Policy</strong>
          <br />
          We may update this Privacy Policy from time to time. <br />
          Users are advised to review this Privacy Policy periodically to be
          aware of any changes.
          <br />
          Changes to the Privacy Policy will take effect immediately upon
          publication in the Simplystock application. <br />
        </p>
        <p>
          <strong>7. Contact</strong>
          <br />
          If you have any questions or concerns regarding this Privacy Policy,
          please contact us via email at
          <br />
          <a href="mailto:bhairavasoft@gmail.com">bhairavasoft@gmail.com</a>.
        </p>
      </div>
      <footer className="py-10">
        <p className="text-center text-sm text-slate-500">
          Copyright © 2024 Simplystock.co | Bhairavasoft Pvt Ltd.
        </p>
      </footer>
    </div>
  );
};

export default Privacy;
