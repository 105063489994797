import React from "react";
import RectangleSupplyChainOne from "../../Images/RectangleSupplyChainOne";
import SupplyChainTwo from "../../Images/SupplyChainTwo";
import SupplyChainThree from "../../Images/SupplyChainThree";

const SupplyChain = () => {
  return (
    <div className="md:py-8 py-24 px-[40px]">
      <h1 className="font-[700] text-[36px] md:text-[32px] sm:text-[28px]  mb-6 text-center">
        Designed for Small and Medium-Sized Businesses
      </h1>
      <div className="flex justify-around items-center mt-10 flex-wrap gap-y-6">
        <div className="rounded-2xl overflow-hidden border-2">
          <RectangleSupplyChainOne />
          <div className="p-8 flex flex-col gap-2">
            <h1 className="text-[20px] font-[700]">Distributors</h1>
            <p className="text-[20px]">
              Effortlessly manage inventory
              <br />
              and ensure timely deliveries.
            </p>
          </div>
        </div>
        <div className="rounded-2xl overflow-hidden border-2">
          <SupplyChainTwo />
          <div className="p-8 flex flex-col gap-2">
            <h1 className="text-[20px] font-[700]">Retailers</h1>
            <p className="text-[20px]">
              Simplify stock management<br/>and boost sales.
            </p>
          </div>
        </div>
        <div className="rounded-2xl overflow-hidden border-2">
          <SupplyChainThree />
          <div className="p-8 flex flex-col gap-2">
            <h1 className="text-[20px] font-[700]">Suppliers</h1>
            <p className="text-[20px]">
              Track inventory in real time<br/>to meet demand.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SupplyChain;
