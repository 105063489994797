import React from "react";
import { BsWhatsapp } from "react-icons/bs";
import { MdOutlineLocationOn } from "react-icons/md";

import { MdOutlineEmail } from "react-icons/md";

const ContactUs = () => {
  return (
    <div className="py-[40px] h-screen">
      <div className="text-center mt-[80px]">
        <h1 className="text-[65px] font-[700]">Contact</h1>
        <p className="text-[25px] text-slate-600 font-[600] tracking-wide">
          Contact us if you need.
        </p>
      </div>

      <div className=" m-auto mt-[130px] flex flex-col gap-4 w-fit">
        <div className="flex  items-center gap-3">
          <div>
            <MdOutlineLocationOn size={25} className="text-gray-400 " />
          </div>
          <div className="text-[20px] text-slate-600 font-[500]">
            Rajasthan - Udaipur, India
          </div>
        </div>
        <div className="flex items-center gap-3">
          <a
            href="mailto:bhairavasoft@gmail.com"
            className="flex items-center gap-3"
          >
            <MdOutlineEmail size={25} className="text-gray-400" />
            <span className="text-[20px] text-slate-600 font-[500]">
              bhairavasoft@gmail.com
            </span>
          </a>
        </div>
        <div className="flex items-center gap-3">
          <a
            href="https://wa.me/919929970571"
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center gap-3"
          >
            <BsWhatsapp size={20} className="text-gray-400 ml-[2px]" />
            <span className="text-[20px] text-slate-600 font-[500] ml-1">
              +91 99299 70571
            </span>
          </a>
        </div>
      </div>
      <p className="text-[20px] text-slate-500 font-[500] text-center mt-[100px]">
        Copyright © 2024 Simplystock.co | Bhairavasoft Pvt Ltd.
      </p>
    </div>
  );
};

export default ContactUs;
