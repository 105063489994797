import React from "react";
import Eclipeseone from "../../Images/Eclipeseone";
import EclipseThree from "../../Images/EclipseThree";
import EclipseTwo from "../../Images/EclipseTwo";
import DeliveryImage from "../../Images/DeliveryImage";
import PhoneChatsImage from "../../Images/PhoneChatsImage";
import ClockImage from "../../Images/ClockImage";

const DesignedForPage = () => {
  return (
    <div className="md:py-8 py-16 px-[40px]" id="features">
      <div className="flex flex-col justify-center items-center">
        <h1 className="font-[700] text-[36px]  mb-6 text-center">
          Designed for Small and Medium-Sized Businesses
        </h1>
        <p className="font-[400] text-[16px] md:text-[16px] sm:text-[14px] mb-12 text-center">
          Simply handles inventory from hundreds to thousands of products,
          making it perfect for MSMEs. This free tool helps you efficiently
          manage stock, reduce losses, and drive profitability.
        </p>
      </div>
      <div className="flex justify-center flex-wrap gap-4">
        <div className="">
          <div className="relative">
            <Eclipeseone />
            <div className="absolute inset-0 flex justify-center items-center">
              <DeliveryImage />
            </div>
          </div>
          <h2 className="text-center font-[700] text-[20px] mt-3">
            Handles Inventory
          </h2>
        </div>
        <div className="">
          <div className="relative">
            <EclipseTwo />
            <div className="absolute inset-0 flex justify-center items-center">
              <PhoneChatsImage />
            </div>
          </div>
          <h2 className="text-center font-[700] text-[20px] mt-3">
            User-Friendly Interface
          </h2>
        </div>
        <div className="">
          <div className="relative">
            <EclipseThree />
            <div className="absolute inset-0 flex justify-center items-center">
              <ClockImage />
            </div>
          </div>
          <h2 className="text-center font-[700] text-[20px] mt-3">
            Real Time Tracking
          </h2>
        </div>
      </div>
    </div>
  );
};

export default DesignedForPage;
