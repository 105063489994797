import React from "react";

const Eclipeseone = () => {
  return (
    <svg
      width="387"
      height="194"
      viewBox="0 0 387 194"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M387 193.5C387 142.181 366.613 92.9631 330.325 56.6748C294.037 20.3866 244.819 3.87451e-06 193.5 0C142.181 -3.87451e-06 92.9631 20.3865 56.6749 56.6748C20.3866 92.9631 7.74901e-06 142.181 0 193.5L193.5 193.5H387Z"
        fill="url(#paint0_linear_372_903)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_372_903"
          x1="48.8842"
          y1="64.5"
          x2="387"
          y2="194.179"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C6D8FF" />
          <stop offset="1" stopColor="#FBEAFF" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Eclipeseone;
