import React from "react";

const ZigZagImage = () => {
  return (
    <svg
      width="458"
      height="28"
      viewBox="0 0 458 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 8.23263C49.0761 4.97414 188.771 -0.565304 370.939 3.34489"
        stroke="#FF5200"
        strokeWidth="3.73333"
        strokeLinecap="round"
      />
      <path
        d="M104.036 17.7789C137.494 14.0919 237.621 6.08417 370.465 3.54884"
        stroke="#FF5200"
        strokeWidth="3.73333"
        strokeLinecap="round"
      />
      <path
        d="M105.142 17.712C150.402 15.9293 283.883 14.5603 455.73 23.3461"
        stroke="#FF5200"
        strokeWidth="3.73333"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default ZigZagImage;
