import React from "react";

const DesktopIcon = () => {
  return (
    <svg
      width="23"
      height="16"
      viewBox="0 0 23 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.515 12.2662C4.535 12.2662 3.735 11.4662 3.735 10.4862V2.62619C3.735 1.64619 4.535 0.846191 5.515 0.846191H17.655C18.635 0.846191 19.455 1.64619 19.455 2.62619V10.4862C19.455 11.4662 18.635 12.2662 17.655 12.2662H5.515ZM5.155 2.62619V10.4862C5.155 10.6662 5.335 10.8462 5.515 10.8462H17.655C17.855 10.8462 18.015 10.6662 18.015 10.4862V2.62619C18.015 2.44619 17.855 2.26619 17.655 2.26619H5.515C5.335 2.26619 5.155 2.44619 5.155 2.62619ZM20.515 12.9862H22.295V14.0462C22.295 14.6462 21.495 15.1262 20.515 15.1262H2.655C1.675 15.1262 0.875 14.6462 0.875 14.0462V12.9862H20.515ZM12.475 14.0462C12.575 14.0462 12.655 13.9862 12.655 13.8862C12.655 13.7862 12.575 13.7062 12.475 13.7062H10.695C10.595 13.7062 10.515 13.7662 10.515 13.8862C10.515 14.0062 10.595 14.0462 10.695 14.0462H12.475Z"
        fill="#FF5200"
      />
    </svg>
  );
};

export default DesktopIcon;
