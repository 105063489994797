import Home from "./Components/Home/Home";
import { Route, Routes } from "react-router-dom";
import Privacy from "./Pages/Privacy";
import ContactUs from "./Pages/ContactUs";

function App() {
  return (
    <div className="">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/contact-us" element={<ContactUs />} />
      </Routes>
    </div>
  );
}

export default App;
