import React from "react";

const RightArrow = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 8.00293H4"
        stroke="#FF5200"
        strokeWidth="1.26548"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 4L12 8L8 12"
        stroke="#FF5200"
        strokeWidth="1.26548"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default RightArrow;
