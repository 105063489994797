import React from "react";
import RightArrow from "../../Images/RightArrow";
import DashboardMobileView from "../../Images/DashboardMobileView";
import ScanMobileView from "../../Images/ScanMobileView";
import ManageStaffView from "../../Images/ManageStaffView";
import NotificationView from "../../Images/NotificationView";
import HistoryView from "../../Images/HistoryView";

const ManageMentView = () => {
  return (
    <div className="flex flex-col gap-5 mt-8">
      {/* one */}
      <div className="flex flex-row md:flex-col  md:justify-center md:gap-5 justify-between">
        <div className="md:w-[100%] w-[50%]  flex flex-col justify-center items-start">
          <h1 className="text-[#A594F9] text-[24px] font-[700]">
            Your All-in-One Business Hub
          </h1>
          <h1 className="font-[700] mt-6 w-full text-[44px] text-black">
            Organize, Track, and Grow Your Business
          </h1>
          <p className="text-[16px] mt-5 w-full">
            Simply brings all essential tools together in one app. Manage your
            inventory, track orders, oversee staff, and monitor finances
            seamlessly from a single platform. Simplify daily operations and
            focus on what matters—growing your business.
          </p>
          <div className="mt-5 w-full">
            <button className="text-[#FF5200] flex justify-center items-center gap-2">
              Learn More
              <RightArrow />
            </button>
          </div>
        </div>
        <div className="md:w-[100%] w-[40%] flex justify-center items-center">
          <DashboardMobileView />
        </div>
      </div>

      {/* two */}

      <div className="flex flex-row  md:flex-col-reverse md:justify-center md:gap-5 justify-between">
        <div className="md:w-[100%]  w-[40%] flex justify-center items-center">
          <ScanMobileView />
        </div>
        <div className="md:w-[100%] w-[50%] flex flex-col justify-center items-start">
          <h1 className="text-[#0B8494] text-[24px] font-[700]">Automation</h1>
          <h1 className="font-[700] mt-6 w-full text-[44px] text-black">
            Streamline Stock Management
          </h1>
          <p className="text-[16px] mt-5 w-full">
            Simplify your stock management processes with barcode scanning.
            Quickly add or remove items from inventory by scanning barcodes with
            your smartphone or a barcode scanner. This feature reduces manual
            data entry errors and speeds up inventory audits.
          </p>
          <div className="mt-5 w-full">
            <button className="text-[#FF5200] flex justify-center items-center gap-2">
              Learn More
              <RightArrow />
            </button>
          </div>
        </div>
      </div>

      {/* three */}

      <div className="flex flex-row md:flex-col  md:justify-center md:gap-5 justify-between">
        <div className="md:w-[100%] w-[50%] flex flex-col justify-center items-start">
          <h1 className="text-[#F05A7E] text-[24px] font-[700]">
            Team Oversight
          </h1>
          <h1 className="font-[700] mt-6 w-full text-[44px] text-black">
            Manage Staff and Finances with Ease
          </h1>
          <p className="text-[16px] mt-5 w-full">
            Effortlessly manage employee access, monitor performance, and track
            financials—all from the Simply App. Make informed business decisions
            with ease.
          </p>
          <div className="mt-5 w-full">
            <button className="text-[#FF5200] flex justify-center items-center gap-2">
              Learn More
              <RightArrow />
            </button>
          </div>
        </div>
        <div className="md:w-[100%] w-[40%] flex justify-center items-center">
          <ManageStaffView />
        </div>
      </div>

      {/* four */}

      <div className="flex flex-row  md:flex-col-reverse md:justify-center md:gap-5 justify-between">
        <div className="md:w-[100%] w-[40%] flex justify-center items-center">
          <NotificationView />
        </div>
        <div className="md:w-[100%]  w-[50%] flex flex-col justify-center items-start">
          <h1 className="text-[#125B9A] text-[24px] font-[700]">Forecasting</h1>
          <h1 className="font-[700] mt-6 w-full text-[44px] text-black">
            Stay Ahead with Smart Predictions
          </h1>
          <p className="text-[16px] mt-5 w-full">
            Leverage smart algorithms to predict future inventory needs based on
            historical data, sales trends, and seasonal demand. This feature
            helps you optimize stock levels, reducing waste and ensuring that
            you have the right products available when your customers need them.
          </p>
          <div className="mt-5 w-full">
            <button className="text-[#FF5200] flex justify-center items-center gap-2">
              Learn More
              <RightArrow />
            </button>
          </div>
        </div>
      </div>

      {/* five */}

      <div className="flex flex-row md:flex-col  md:justify-center md:gap-5 justify-between">
        <div className="md:w-[100%] w-[50%] flex flex-col justify-center items-start">
          <h1 className="text-[#FFBE98] text-[24px] font-[700]">Visibility</h1>
          <h1 className="font-[700] mt-6 w-full text-[44px] text-black">
            Always Know What You Have
          </h1>
          <p className="text-[16px] mt-5 w-full">
            Keep an accurate count of your inventory at all times with real-time
            updates. The Simply App provides instant visibility into stock
            levels, allowing you to make informed decisions quickly and avoid
            overstocking or stockouts.
          </p>
          <div className="mt-5 w-full">
            <button className="text-[#FF5200] flex justify-center items-center gap-2">
              Learn More
              <RightArrow />
            </button>
          </div>
        </div>
        <div className="md:w-[100%] w-[40%] flex justify-center items-center">
          <HistoryView />
        </div>
      </div>
    </div>
  );
};

export default ManageMentView;
