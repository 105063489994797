import React from "react";
import AppleIcon from "../../Images/AppleIcon";
import AndroidIcon from "../../Images/AndroidIcon";
import DesktopIcon from "../../Images/DesktopIcon";
import ZigZagImage from "../../Images/ZigZagImage";
import DashboardImage from "../../Images/DashboardImage";
import "./Home.css";
import Management from "../Management/Management";
import DesignedForPage from "../DesignedForPage/DesignedForPage";
import Navbar from "../Navbar/Navbar";
import BoostGrowth from "../BoostGrowth/BoostGrowth";
import SupplyChain from "../SupplyChain/SupplyChain";
import EveryIndustry from "../EveryIndustry/EveryIndustry";
import Footer from "../Footer/Footer";
import Testimonials from "../Testimonials/Testimonials";
const Home = () => {
  console.log("work");

  return (
    <>
      <Navbar />
      <div
        className="h-screen rounded-lg bg-[linear-gradient(to_bottom,rgba(255,216,198,1),rgba(255,241,234,1))] mainContainer"
        style={{ height: "calc(100vh - 80px)" }}
      >
        {/* <div className=" w-[50%] flex flex-col justify-center items-start pl-[100px]">
          <h1 className="lg:text-[42px] text-[44px] md:text-center text-start font-[700] leading-normal  w-full">
            Effortless Inventory <br /> Management for <br />
            <span className="text-[#FF5200]">Growing Businesses</span>
          </h1>
          <div className="w-full flex md:justify-center md:items-center">
            <ZigZagImage />
          </div>
          <p className="text-[20px] md:text-center text-start w-full mt-6">
            Streamline your operations with a simple,
            <br /> powerful inventory solution.
          </p>

          <div className="flex sm:flex-col flex-row md:justify-center md:items-center justify-start items-start gap-5 w-full mt-6">
            <button className="flex justify-center items-center gap-2 lg:text-sm max-w-[230px] w-[100%] rounded-lg outline-none  py-2.5  font-[600] bg-[#ff5200] text-white">
              <AppleIcon /> Download for iOS
            </button>
            <button className="flex justify-center items-center gap-2 lg:text-sm max-w-[230px] w-[100%] outline-none rounded-lg py-2.5 font-[600] bg-[#ff5200] text-white">
              <AndroidIcon /> Download for Android
            </button>
          </div>

          <div className="w-full mt-4 flex md:justify-center md:items-center justify-start items-start">
            <button className="text-[#FF5200] max-w-[480px] w-[100%] font-[600] border-[1px] rounded-lg border-[#FF5200] py-2.5 flex justify-center items-center gap-2">
              <DesktopIcon /> Start Using on Desktop
            </button>
          </div>
        </div> */}

        <div className="container-one">
          <h1 className="text-header leading-normal">
            Effortless Inventory <br /> Management for <br />
            <span className="text-[#FF5200]">Growing Businesses</span>
          </h1>
          <div className="zigzag-image">
            <ZigZagImage />
          </div>
          <p className="paragraph-main">
            Streamline your operations with a simple,
            <br /> powerful inventory solution.
          </p>

          <div className="button-for-ios-android">
            <button
              className="button-ios rounded-lg py-2.5"
              onClick={() =>
                window.open(
                  "https://apps.apple.com/us/app/simply-simple-stock-inventory/id6737231476",
                  "_blank"
                )
              }
            >
              <AppleIcon /> Download for iOS
            </button>
            <button
              className="button-android rounded-lg py-2.5"
              onClick={() =>
                window.open(
                  "https://play.google.com/store/apps/details?id=com.bhairavasoft.simply",
                  "_blank"
                )
              }
            >
              <AndroidIcon /> Download for Android
            </button>
          </div>

          <div className="start-using-desktop">
            <button
              onClick={() =>
                window.open("https://web.simplystock.co/login", "_blank")
              }
              className="text-[#FF5200] max-w-[480px] w-[100%] font-[600] border-[1px] rounded-lg border-[#FF5200] py-2.5 flex justify-center items-center gap-2"
            >
              <DesktopIcon /> Start Using on Desktop
            </button>
          </div>
        </div>

        <div className="container-two w-[60%] flex justify-end items-center">
          <DashboardImage />
        </div>
      </div>

      <Management />
      <DesignedForPage />
      <BoostGrowth/>
      <SupplyChain/>
      <EveryIndustry/>
      <Testimonials/>
      <Footer/>
    </>
  );
};

export default Home;
