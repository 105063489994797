import React from "react";

const AppleIcon = () => {
  return (
    <svg
      width="18"
      height="21"
      viewBox="0 0 18 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.6303 11.5204C14.6214 9.88708 15.3602 8.65433 16.8555 7.74645C16.0189 6.5493 14.755 5.89065 13.0861 5.76158C11.5062 5.63697 9.77943 6.68281 9.14748 6.68281C8.47992 6.68281 6.94899 5.80609 5.74739 5.80609C3.26407 5.84614 0.625 7.78651 0.625 11.734C0.625 12.9 0.838618 14.1046 1.26585 15.3477C1.8355 16.981 3.89158 20.9863 6.03666 20.9196C7.15816 20.8929 7.95032 20.123 9.41005 20.123C10.8253 20.123 11.5596 20.9196 12.8101 20.9196C14.973 20.8884 16.8333 17.248 17.3762 15.6103C14.4746 14.244 14.6303 11.6049 14.6303 11.5204ZM12.1114 4.21285C13.3264 2.77093 13.2151 1.45807 13.1795 0.986328C12.107 1.04863 10.8653 1.71619 10.1577 2.53951C9.37889 3.42069 8.92051 4.51103 9.01841 5.73933C10.18 5.82834 11.2392 5.23199 12.1114 4.21285Z"
        fill="white"
      />
    </svg>
  );
};

export default AppleIcon;
