import React from 'react'
import SimplyLargePoster from '../../Images/SimplyLargePoster'

const SimpleBigImage = () => {
  return (
    <div className='mt-5 flex justify-center items-center'>
        <SimplyLargePoster/>
    </div>
  )
}

export default SimpleBigImage