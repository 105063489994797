import React from "react";

const SupplyChainThree = () => {
  return (
    <svg
      width="387"
      height="119"
      viewBox="0 0 387 119"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="387" height="65" fill="#FF8A8A" />
      <rect x="30" y="37" width="82" height="82" rx="12" fill="white" />
      <path
        d="M70.2667 62.2732H67.4667C67.14 62.2732 66.8133 62.4599 66.6267 62.7866L64.06 67.7332L61.4933 62.7866C61.3533 62.4599 61.0267 62.2732 60.6533 62.2732H57.8533C55.1933 62.2732 53 64.4666 53 67.1266V78.6532C53 81.1266 54.7733 83.1799 57.1067 83.6466V102.08C57.1067 104.227 58.88 106 61.0267 106C62.24 106 63.3133 105.44 64.0133 104.6C64.7133 105.44 65.7867 106 67 106C69.1467 106 70.92 104.227 70.92 102.08V83.6466C73.2533 83.2266 75.0267 81.1266 75.0267 78.6532V67.1266C75.0733 64.4666 72.9267 62.2732 70.2667 62.2732ZM61.0733 104.133C59.9533 104.133 59.02 103.2 59.02 102.08V83.7399H63.1267V102.08C63.1267 103.2 62.1933 104.133 61.0733 104.133ZM67.0467 104.133C65.9267 104.133 64.9933 103.2 64.9933 102.08V83.7399H69.1V102.08C69.1 103.2 68.1667 104.133 67.0467 104.133ZM70.9667 81.7332V68.7599C70.9667 68.2466 70.5467 67.8266 70.0333 67.8266C69.52 67.8266 69.1 68.2466 69.1 68.7599V81.8266H59.02V68.7599C59.02 68.2466 58.6 67.8266 58.0867 67.8266C57.5733 67.8266 57.1533 68.2466 57.1533 68.7599V81.7332C55.8467 81.3132 54.9133 80.0999 54.9133 78.6999V67.1266C54.9133 65.4932 56.2667 64.1399 57.9 64.1399H60.14L63.2667 70.1599C63.4067 70.4866 63.7333 70.6732 64.1067 70.6732C64.4333 70.6732 64.76 70.4866 64.9467 70.1599L68.0733 64.1399H70.3133C71.9467 64.1399 73.3 65.4932 73.3 67.1266V78.6532C73.2067 80.0999 72.2733 81.3132 70.9667 81.7332Z"
        fill="#FF8A8A"
      />
      <path
        d="M64.06 61.4333C67.2333 61.4333 69.7533 58.8667 69.7533 55.74C69.7533 52.6133 67.1866 50 64.06 50C60.8866 50 58.3666 52.5667 58.3666 55.6933C58.3666 58.82 60.8866 61.4333 64.06 61.4333ZM67.8866 55.6933C67.8866 57.7933 66.16 59.52 64.06 59.52C61.96 59.52 60.2333 57.7933 60.2333 55.6933C60.2333 53.5933 61.96 51.8667 64.06 51.8667C66.16 51.8667 67.8866 53.5933 67.8866 55.6933Z"
        fill="#FF8A8A"
      />
      <path
        d="M104.427 102.127H92.1071C92.2471 101.66 92.3404 101.147 92.3404 100.633C92.3404 98.02 90.3337 95.8733 87.7671 95.6867L85.2004 73.24C85.1537 72.7733 84.7337 72.4 84.2671 72.4H78.8071C78.2937 72.4 77.8737 72.82 77.8737 73.3333C77.8737 73.8467 78.2937 74.2667 78.8071 74.2667H83.4271L85.9004 95.92C83.8937 96.5733 82.4004 98.44 82.4004 100.633C82.4004 103.387 84.6404 105.58 87.3471 105.58C88.7937 105.58 90.1004 104.973 90.9871 103.947H104.38C104.894 103.947 105.314 103.527 105.314 103.013C105.314 102.5 104.94 102.127 104.427 102.127ZM87.3937 97.5533C89.1204 97.5533 90.4737 98.9533 90.4737 100.633C90.4737 102.313 89.0737 103.713 87.3937 103.713C85.6671 103.713 84.3137 102.313 84.3137 100.633C84.3137 98.9533 85.6671 97.5533 87.3937 97.5533Z"
        fill="#FF8A8A"
      />
    </svg>
  );
};

export default SupplyChainThree;
